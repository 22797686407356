import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   // Cliente
   {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
   },
   {
      path: '/categorias',
      name: 'Categorias',
      component: () => import('../views/Categorias.vue')
   },
   {
      path: '/historia',
      name: 'História',
      component: () => import('../views/Historia.vue')
   },
   {
      path: '/noticias',
      name: 'Noticias',
      component: () => import('../views/Noticias.vue')
   },
   {
      path: '/produtos',
      name: 'Produtos',
      component: () => import('../views/Produtos.vue')
   },
   {
      path: '/produto/:codigo',
      name: 'Produto',
      component: () => import('../views/Produto.vue')
   },
   {
      path: '/downloads',
      name: 'Downloads',
      component: () => import('../views/Downloads.vue')
   },
   {
      path: '/encontreRevenda',
      name: 'Encontre_Revenda',
      component: () => import('../views/EncontreRevenda.vue')
   },
   {
      path: '/assistenciaTecnica',
      name: 'Assistência_Técnica',
      component: () => import('../views/AssistenciaTecnica.vue')
   },
   {
      path: '/noticia/:id',
      name: 'Noticia',
      component: () => import('../views/Noticia.vue')
   },
   {
      path: '/produtoDivulgacao/:codigo',
      name: 'Produto_Divulgação',
      component: () => import('../views/ProdutoDivulgacao.vue')
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   $(".offcanvas__area").removeClass("opened");
   $(".body-overlay").removeClass("opened");
   $("#app > .wrapper").removeClass("toggled");

   store.dispatch('saveHistory', to)

   if (to.params.codigo) {
      document.title = 'Nakashi - Cód. '+ to.params.codigo;
   } else {
      document.title = 'Nakashi - '+ String(to.name).replace(/_/g, " ").replace(/-/g, "/").replace("Adm", "");
   }
   
   if (localStorage.ccmPesquisa) {
      if (to.name == 'Produtos') {
         store.dispatch('resgatarPesquisa', JSON.parse(localStorage.ccmPesquisa))
      } else if (to.name != 'Produto') {
         localStorage.removeItem('ccmPesquisa')
      }
   }

   if (routes.filter(rota => rota.name == to.name)[0] == null) {
      next('/')
   } else {
      next()
   }
})

export default router
